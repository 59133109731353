


































































import { Component, Vue, Mixins, Watch } from 'vue-property-decorator'
import { VueEditor } from 'vue2-editor'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import RadioToggle from '@/components/atoms/RadioToggle.vue'
import LabelBase from '@/components/atoms/LabelBase.vue'
import CheckboxSquare from '@/components/atoms/CheckboxSquare.vue'
import DatePickerBase from '@/components/atoms/DatePickerBase.vue'
import TimePickerBase from '@/components/atoms/TimePickerBase.vue'
import LocalMoment from '@/components/atoms/LocalMoment.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'

@Component({
  components: {
    VueEditor,
    ButtonBase,
    RadioToggle,
    LabelBase,
    CheckboxSquare,
    DatePickerBase,
    TimePickerBase,
    SelectBase,
  },
})
export default class NotificationEdit extends Mixins(LocalMoment) {
  private isProcessing = false

  private service = Vue.prototype.$cookies.get('dataGdls').service

  private terms = {
    start: { date: this.today().format('YYYY/MM/DD'), time: this.today().format('HH:mm') },
    end: { date: '', time: '' },
  }

  private notificationEditItem: {
    roles: number[]
    title: string
    startedAt: string
    endedAt: string
    isDisplaying: boolean
    message: string
    code?: string
  } = {
    roles: [],
    title: '',
    startedAt: '',
    endedAt: '',
    isDisplaying: true,
    message: '',
    code: this.service === 'quiz' ? 'qz' : '',
  }

  private targetRoles: { label: string; value: number; checked: boolean; disabled: boolean }[] = []

  private roleIds(): number[] {
    return this.targetRoles
      .filter((target: { checked: boolean }) => {
        return target.checked === true
      })
      .map((target: { value: number }) => {
        return target.value
      })
  }

  private clearEndedAt(): void {
    const date_clear_btn = document.getElementsByClassName('date-picker-base__clear')[0] as HTMLElement
    if (date_clear_btn) date_clear_btn.click()
    const time_clear_btn = document.getElementsByClassName('clear-btn has-custom-btn')[0] as HTMLElement
    if (time_clear_btn) time_clear_btn.click()
  }

  /**
   * バリデーションエラー
   */
  private get notificationErrors(): { roles: string; title: string; start: string; end: string; message: string } {
    const errors: { roles: string; title: string; start: string; end: string; message: string } = {
      roles: '',
      title: '',
      start: '',
      end: '',
      message: '',
    }
    if (this.roleIds().length === 0) {
      errors.roles = '対象を選択してください'
    }
    if (this.notificationEditItem.title === '') {
      errors.title = '件名を入力してください'
    } else if (this.notificationEditItem.title.length > 50) {
      errors.title = '件名は50文字までです'
    }
    const start_datetime = this.createDateTimeFromString(this.terms.start)
    const end_datetime = this.createDateTimeFromString(this.terms.end)
    if (start_datetime === null) {
      errors.start = '表示開始日時を入力してください'
    }
    if ((this.terms.end.date !== '' || this.terms.end.time !== '') && end_datetime === null) {
      errors.end = '表示終了日時を入力してください'
    }
    if (start_datetime !== null && end_datetime !== null && end_datetime.isBefore(start_datetime)) {
      errors.end = '表示終了日時は表示開始日時より後の日時を入力してください'
    }
    if (this.notificationEditItem.message === '') {
      errors.message = '内容を入力してください'
    }
    return errors
  }

  /**
   * ボタンの色（ボタン操作制御）
   */
  private get colortype(): string {
    const permitted = Object.values(this.notificationErrors).every((value) => value === '')

    return permitted ? 'green' : 'pointer-events-none'
  }

  private createOrUpdateNotification(): void {
    if (this.$route.params.id) {
      this.updateNotification()
    } else {
      this.createNotification()
    }
  }

  private processDataBeforeSubmit() {
    const roleIdStudent = this.targetRoles.find((role) => role.label === '生徒')?.value as number
    const roleIds = this.roleIds()
    if (roleIds.includes(-1) && !roleIds.includes(roleIdStudent)) {
      roleIds.push(roleIdStudent)
    }
    if (roleIds.includes(-1)) {
      const index = roleIds.indexOf(5)
      if (index > -1) {
        roleIds.splice(index, 1)
      }
    }
    this.notificationEditItem.roles = roleIds
    !this.notificationEditItem.code && delete this.notificationEditItem.code
    this.notificationEditItem.startedAt = this.formatDateTimeFromString(this.terms.start)
    this.notificationEditItem.endedAt = this.formatDateTimeFromString(this.terms.end)
  }

  /**
   * お知らせを追加するメソッド
   */
  private async createNotification() {
    if (this.isProcessing === false) {
      this.isProcessing = true

      this.processDataBeforeSubmit()
      await Vue.prototype.$http.httpWithToken.post('/notifications', this.notificationEditItem).then(() => {
        this.$router.push('/admin/notification')
      })
    }
  }

  /**
   * お知らせを更新するメソッド
   */
  private async updateNotification() {
    if (this.isProcessing === false) {
      this.isProcessing = true
      this.processDataBeforeSubmit()
      await Vue.prototype.$http.httpWithToken
        .patch(`/notifications/${this.$route.params.id}`, this.notificationEditItem)
        .then(() => {
          this.$router.push('/admin/notification')
        })
        .catch((error: any) => {
          if (error.response.data.status === 404) {
            alert('データが見つかりません。お知らせ管理画面に戻ります。')
            this.$router.push('/admin/notification')
          }
        })
    }
  }

  /**
   * お知らせを取得する
   */
  private async loadNotification() {
    if (this.$route.params.id) {
      // 編集時はお知らせを取得後対象ロールを取得する
      await Vue.prototype.$http.httpWithToken
        .get(`/notifications/${this.$route.params.id}`)
        .then((res: any) => {
          this.terms.start = this.formatDateTimeFromDateObj(res.data.startedAt)
          this.terms.end = this.formatDateTimeFromDateObj(res.data.endedAt)
          this.notificationEditItem.title = res.data.title
          this.notificationEditItem.isDisplaying = res.data.isDisplaying
          this.notificationEditItem.message = res.data.message
          this.notificationEditItem.code = res.data.code
          this.loadTargetRoles(res.data.roles)
        })
        .catch((error: any) => {
          if (error.response.data.status === 404) {
            alert('データが見つかりません。お知らせ管理画面に戻ります。')
            this.$router.push('/admin/notification')
          }
        })
    } else {
      // 新規時は対象ロールのみ取得する
      this.loadTargetRoles(null)
    }
  }

  private async loadTargetRoles(selectedRoles: number[] | null) {
    const names = 'academy,teacher,parent,student'
    await Vue.prototype.$http.httpWithToken.get(`/roles?names=${names}`).then((res: any) => {
      this.targetRoles = res.data.roles.map((role: { id: number; type: string }) => {
        return {
          label: role.type,
          value: role.id,
          checked: this.roleChecked(selectedRoles, role),
        }
      })
    })
  }

  private roleChecked(selectedRoles: number[] | null, role: { id: number; type: string }) {
    if (selectedRoles) return selectedRoles.includes(role.id)
    if (this.service !== 'quiz') return true
    if (role.type === '生徒' && this.service === 'quiz') return true
    else return false
  }

  private async mounted() {
    this.loadNotification()
  }
}
